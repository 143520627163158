import { BaseRecordValidationError } from "../../base/baseErrors/base-mcase-error";
import { BaseRecordValidatorType } from "../../../../core/domain/enums/base-record-validator-type.enum";

/**
 * Represents a warning related to a record validation error.
 * Extends the `BaseRecordValidationError` class.
 *
 * @extends BaseRecordValidationError
 */
export class RecordWarning extends BaseRecordValidationError {
    override readonly type: BaseRecordValidatorType =
        BaseRecordValidatorType.RecordWarning;
    constructor(message: string) {
        super();
        this.message = message;
    }
}
