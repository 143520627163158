import { BaseRecordValidationError } from "../../base/baseErrors/base-mcase-error";
import { BaseRecordValidatorType } from "../../../../core/domain/enums/base-record-validator-type.enum";
import { RecordErrorType } from "../../../../core/domain/enums/record-error-type.enum";

export class RecordError extends BaseRecordValidationError {
    override readonly type: BaseRecordValidatorType =
        BaseRecordValidatorType.RecordError;
    errorType!: RecordErrorType;
    override message: string = "";
    constructor(message: string, errorType: RecordErrorType) {
        super();
        this.message = message;
        this.errorType = errorType;
    }

    getErrorType(): RecordErrorType {
        return this.errorType;
    }
}
