import { BaseRecordValidationError } from "../../base/baseErrors/base-mcase-error";
import { BaseRecordValidatorType } from "../../../../core/domain/enums/base-record-validator-type.enum";

export class RecordMessage extends BaseRecordValidationError {
    override readonly type: BaseRecordValidatorType =
        BaseRecordValidatorType.RecordMessage;
    constructor(message: string) {
        super();
        this.message = message;
    }
}
